const dashboardNavData = [
  {
    navItemContainer: "My Dashboard",
    navItems: [{ url: "/dashboard", text: "Dashboard", dropdown: false }],
  },
  {
    navItemContainer: "My Earnings",
    navItems: [
      { url: "wallet", text: "My Wallet", dropdown: false },
      {
        url: "referral-earnings",
        text: "Referral Earnings",
        dropdown: false,
      },
      {
        url: "group-sell",
        text: "Group Sale Earnings",
        dropdown: false,
      },
      {
        url: "positional-rewards",
        text: "Promotional Status",
        dropdown: false,
      },
      {
        url: "pi",
        text: "Profit Incentive",
        dropdown: false,
      },
      {
        title: "Royality Income",
        dropdown: true,
        dropDownItems: [
          { url: "generations", text: "All Generations" },
          { url: "generation-1", text: "1st Generation" },
          { url: "generation-2", text: "2nd Generation" },
          { url: "generation-3", text: "3rd Generation" },
          { url: "generation-4", text: "4th Generation" },
          { url: "generation-5", text: "5th Generation" },
        ],
      },
      {
        title: "Sales Royality Income",
        dropdown: true,
        dropDownItems: [
          { url: "sales-royality-income", text: "All Generations" },
          { url: "s-gen-1", text: "1st Generation" },
          { url: "s-gen-2", text: "2nd Generation" },
          { url: "s-gen-3", text: "3rd Generation" },
          { url: "s-gen-4", text: "4th Generation" },
          { url: "s-gen-5", text: "5th Generation" },
        ],
      },
      {
        url: "shop-refer",
        text: "Shop Referral Earnings",
        dropdown: false,
      },
      {
        url: "shop-pi",
        text: "Shop Profit Incentive",
        dropdown: false,
      },
    ],
  },
  {
    navItemContainer: "My Team",
    navItems: [
      { url: "team", text: "Team Maintenance", dropdown: false },
      // {
      //   url: "add-team",
      //   text: "Add New Team Member",
      //   dropdown: false,
      // },
      {
        url: "team-promotions",
        text: "Team Promotional Status",
        dropdown: false,
      },
      {
        url: "transfer",
        text: "Transfer Points to Team",
        dropdown: false,
      },
    ],
  },
  {
    navItemContainer: "Withdraw",
    navItems: [
      { url: "convert", text: "Convert", dropdown: false },
      { url: "withdraw", text: "Withdraw", dropdown: false },
      {
        title: "History",
        dropdown: true,
        dropDownItems: [
          { url: "withdraw-history", text: "Withdrawal History" },
          { url: "convert-history", text: "Convert History" },
        ],
      },
    ],
  },
  {
    navItemContainer: "My Shopping",
    navItems: [
      { url: "shop", text: "Shop", dropdown: false },
      { url: "/cart", text: "Cart", dropdown: false },
      {
        url: "orders",
        text: "Orders",
        dropdown: false,
      },
    ],
  },
];

export default dashboardNavData;
